<template>
    <div>
        <div class="page-title">
            <span>自动分销</span>
            <span class="page-title-tips">
                （根据微信官方政策，自动分账总比例须≤30%）
            </span>
        </div>
        
        <a-tabs v-model="tabKey">
            <a-tab-pane key="per" tab="分销比例设置">
                <template v-if="levels">
                    <div class="empty-level" v-if="!levels.length">
                        目前还没有设置会员等级，
                        <router-link to="/ast/serve/level">立即设置</router-link>
                    </div>
                    <div v-else>
                        <table class="share-setting-table bordered">
                            
                            <thead>
                            <tr>
                                <td></td>
                                <template v-for="(title,key) in shareAttributes">
                                    <td :key="key">{{ title }}</td>
                                </template>
                            </tr>
                            </thead>
                            
                            <tbody>
                            
                            <template v-for="(level,lk) in levels">
                                
                                <tr :key="lk">
                                    
                                    <td class="text-right">{{ level.name }}</td>
                                    
                                    <template v-for="(title,key) in shareAttributes">
                                        <td :key="key" :title="title">
                                            <a-input type="number" :max="30" :min="0" class="ss-input"
                                                     v-model=" level.share_setting[key] "/>
                                            <span class="per-unit">%</span>
                                        </td>
                                    </template>
                                
                                </tr>
                            
                            </template>
                            
                            </tbody>
                        
                        </table>
                    
                    </div>
                </template>
            
            </a-tab-pane>
            <a-tab-pane key="method" tab="分账方式设置">
                
                <a-radio-group v-model="shareRadioValue">
                    <a-radio value="t1">T+1天后自动分账</a-radio>
                    <a-radio value="t0">手动确认分账</a-radio>
                    <a-radio value="tn">
                        <a-select :default-value="3" v-model="shareSelectValue">
                            <a-select-option :value="3">3天后分账</a-select-option>
                            <a-select-option :value="5">5天后分账</a-select-option>
                            <a-select-option :value="7">7天后分账</a-select-option>
                            <a-select-option :value="10">10天后分账</a-select-option>
                        </a-select>
                    </a-radio>
                </a-radio-group>
            
            </a-tab-pane>
        </a-tabs>
        
        <div class="flex flex-left-center mt-10">
            <button class="btn" @click="saveConfig">确认</button>
            <span v-if="tabKey === 'per' " class="share-setting-tips">*不填或填为“0”代表不进行分佣</span>
        </div>
    
    </div>
</template>
<script>
import api from "@/repo/api";

export default {
    name: "ShareSetting",
    data() {
        return {
            tabKey: "per",
            shareAttributes: {},
            levels: null,
            shareTiming: 0,
            
            shareRadioValue: 0,
            shareSelectValue: 3,
        }
    },
    mounted() {
        this.fetchLevels()
    },
    methods: {
        fetchLevels() {
            api.get('/ast-app/level-share-setting', (data) => {
                
                let config = data.data
                
                this.levels = config.level_setting.length ? config.level_setting : []
                
                this.shareAttributes = config.share_attributes
                this.shareTiming = config.share_timing ?? 0
                
                this.shareRadioValue = this.shareTiming <= 1 ? 't' + this.shareTiming : 'tn'
                this.shareSelectValue = this.shareTiming <= 1 ? 3 : this.shareTiming
                
            });
        },
        saveConfig() {
            let data = []
            
            this.levels.forEach(level => {
                
                level.share_setting.level_id = level.id
                
                data.push(level.share_setting)
            })
            
            let shareTiming = this.shareSelectValue
            
            if (this.shareRadioValue === 't0') shareTiming = 0;
            if (this.shareRadioValue === 't1') shareTiming = 1;
            
            this.$loading.show()
            
            api.post('/ast-app/change-level-order-share-setting', {
                settings: data,
                share_timing: shareTiming
            }).then(res => {
                this.$loading.hide()
                this.$message.data(res)
            })
        }
    }
}
</script>
<style lang="less" scoped>
.page-title-tips {
    font-weight: 400;
    font-size: 16px;
    color: #999999;
    line-height: 19px;
}

.ss-input {
    width: 64px;
}

.per-unit {
    font-size: 14px;
    color: #333333;
    line-height: 16px;
    margin-left: 8px;
}

.share-setting-tips {
    font-weight: 400;
    font-size: 16px;
    color: #FBB03B;
    line-height: 19px;
    text-align: center;
    margin-left: 12px;
}
</style>